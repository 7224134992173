import { getForLanguage } from "@sixty-six-north/i18n"
import { usePreferredLanguage } from "i18n/StoreHooks"
import React, { FC, ReactNode } from "react"
import { I18nextProvider } from "react-i18next"

export const I18NextProvider: FC<{
  children?: ReactNode
}> = ({ children }) => {
  const language = usePreferredLanguage()
  return (
    <I18nextProvider i18n={getForLanguage(language)}>
      {children}
    </I18nextProvider>
  )
}
